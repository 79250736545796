import { Stack, Text, Icon } from '@pairy/pds-react';
import Link from 'next/link';

import * as Styled from './index.styles';

interface LineBannerProps {
  link: {
    content: string;
    url: string;
  };
  description: string;
  isMobile: boolean;
  isOpenNewTab: boolean;
}

const LineBanner = ({
  link,
  description,
  isMobile,
  isOpenNewTab,
}: LineBannerProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      gap={16}
      backgroundColor="skyblue_700"
      padding={{ y: isMobile ? 16 : 8 }}
    >
      <Text variant="sm-medium" color="skyblue_100">
        {description}
      </Text>

      <div>
        {isOpenNewTab ? (
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <div>
              <Styled.LinkWrapper gap={4}>
                <Text variant="sm-semibold" color="white">
                  {link.content}
                </Text>
                <Icon icon="ph:caret-right" size={14} color="white" />
              </Styled.LinkWrapper>
            </div>
          </a>
        ) : (
          <Link href={link.url} replace>
            <div>
              <Styled.LinkWrapper gap={4}>
                <Text variant="sm-semibold" color="white">
                  {link.content}
                </Text>
                <Icon icon="ph:caret-right" size={14} color="white" />
              </Styled.LinkWrapper>
            </div>
          </Link>
        )}
      </div>
    </Stack>
  );
};

export default LineBanner;
