import { Text, Icon, Stack } from '@pairy/pds-react';

const LINKED_IN_URL =
  'https://www.linkedin.com/products/pairyofficial-salesclue/';

const LinkedIn = () => {
  return (
    <a href={LINKED_IN_URL} target="_blank" rel="noreferrer noopener nofollow">
      <Stack gap={2} alignItems="center">
        <Icon icon="mdi:linkedin" size={20} color="zinc_200" />
        <Text variant="sm-medium" color="zinc_200">
          linkedIn
        </Text>
      </Stack>
    </a>
  );
};

export default LinkedIn;
