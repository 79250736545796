import { type PropsWithChildren } from 'react';

import { TARGET_DATE } from '../../TopBanner';
import useCheckOverPeriod from '../../TopBanner/useCheckOverPeriod';

import Footer from './Footer';
import Header from './Header';
import * as Styled from './index.styles';

interface Props {
  fullWidth?: boolean;
  className?: string;
  isSetPaddingTop?: boolean;
  isMobile?: boolean;
  tabChangeHandler?: (value: string) => void;
}

const MainLayout = ({
  children,
  fullWidth = false,
  isSetPaddingTop = true,
  className,
}: PropsWithChildren<Props>) => {
  const { isValid } = useCheckOverPeriod({ targetDate: TARGET_DATE });

  return (
    <>
      <Header />
      <Styled.Main
        className={className}
        fullWidth={fullWidth}
        isSetPaddingTop={isSetPaddingTop}
        isBannerVisible={isValid}
      >
        {children}
      </Styled.Main>
      <Footer />
    </>
  );
};

export default MainLayout;
