import styled from '@emotion/styled';
import { Flex } from '@pairy/pds-react';

export const Main = styled.main<{
  fullWidth: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '1080px')};
  margin: 0 auto;
`;

export const ImageWrapper = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;
