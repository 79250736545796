import { useMediaQuery } from 'react-responsive';

import LineBanner from './LineBanner';
import useCheckOverPeriod from './useCheckOverPeriod';

export const TARGET_DATE = '2023-11-12T23:59:59';
export const DESCRIPTION = '파일을 열어보기 전 이메일을 입력받아보세요';

const TopBanner = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { isValid } = useCheckOverPeriod({ targetDate: TARGET_DATE });

  if (!isValid) {
    return null;
  }

  return (
    <LineBanner
      description={DESCRIPTION}
      link={{
        content: '더 알아보기',
        url: 'https://salesclue.gitbook.io/guide/features/collect_email',
      }}
      isMobile={isMobile}
      isOpenNewTab={true}
    />
  );
};

export default TopBanner;
