import Image from 'next/image';
import { Stack, Text } from '@pairy/pds-react';

const SpringCamp = () => {
  return (
    <>
      <Stack gap={8}>
        <Image
          src="/images/spring_camp_logo.png"
          width={26}
          height={43}
          quality={100}
        />
        <Image
          src="/images/strong_ventures_logo.png"
          width={136}
          height={43}
          quality={100}
        />
      </Stack>
      <Text as="p" variant="sm-medium" color="zinc_200" marginTop={16}>
        Backed By
      </Text>
      <Text as="p" variant="lg-medium" color="white">
        Spring Camp, Strong Ventures
      </Text>
    </>
  );
};

export default SpringCamp;
