import styled from '@emotion/styled';
import { Flex } from '@pairy/pds-react';

import { Z_INDEX } from '@/constants/zIndex';

export const FlexWrapper = styled(Flex)`
  z-index: ${Z_INDEX.layoutHeader};
  top: 0;
  border-bottom: 1px solid #e4e4e7;
`;

export const DrawerWrapper = styled(Flex)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(63 63 70 / 25%);
`;

export const DrawerContent = styled(Flex)`
  top: 0;
  left: 0;
  width: 275px;
  height: 100vh;
  padding: 16px;
  border-radius: 0 8px 8px 0;
  box-shadow: ${({ theme }) => theme.elevation.md};
`;

export const MenuWrapper = styled(Flex)`
  &.is-active {
    background-color: ${({ theme }) => theme.colors.skyblue_50};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.skyblue_500};
  }
`;

export const SubMenuWrapper = styled(Flex)`
  &.is-active {
    background-color: ${({ theme }) => theme.colors.skyblue_50};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.skyblue_500};
  }
`;

export const Li = styled.li`
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.zinc_400};
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &.active {
    color: ${({ theme }) => theme.colors.skyblue_400};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.skyblue_400};
  }
`;
