import { useEffect, useState } from 'react';

interface useCheckOverPeriodProps {
  targetDate: string;
}

const useCheckOverPeriod = ({ targetDate }: useCheckOverPeriodProps) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const currentTime = new Date();
    const targetTime = new Date(targetDate);

    setIsValid(currentTime < targetTime);
  }, []);

  return {
    isValid,
  };
};

export default useCheckOverPeriod;
