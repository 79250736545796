import Image from 'next/image';
import { Flex, Stack, Text } from '@pairy/pds-react';

const Pairy = () => {
  return (
    <Stack direction="column" gap={16}>
      <Image
        src="/images/pairy-white-symbol.png"
        width={133.39}
        height={28}
        layout="fixed"
      />
      <Flex direction="column">
        <Text as="p" variant="xs-medium" color="zinc_200">
          기업 명
        </Text>
        <Text as="p" variant="md-medium" color="white">
          Pairy Co., Ltd
        </Text>
      </Flex>
      <address>
        <Text as="p" color="zinc_400" variant="xs-medium">
          주식회사 페어리
          <br />
          서울시 강남구 테헤란로 418 다봉빌딩 11층 (스파크플러스 선릉 2호점)
          <br />
          사업자 등록 번호: 145-81-02014
          <br />
          대표 허수빈 대표 번호: 02-880-0710
        </Text>
      </address>
    </Stack>
  );
};

export default Pairy;
