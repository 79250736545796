import styled from '@emotion/styled';

export const Main = styled.main<{
  fullWidth: boolean;
  isSetPaddingTop: boolean;
  isBannerVisible: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '1080px')};
  padding-top: ${({ isSetPaddingTop, isBannerVisible }) =>
    isSetPaddingTop ? (isBannerVisible ? '80px' : '60px') : '0px'};
  margin: 0 auto;
`;
