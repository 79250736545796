export const FORM_VALIDATIONS = {
  email: {
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
      message: '잘못된 이메일 주소입니다.',
    },
    required: '이메일을 입력해주세요.',
  },
  hyperLink: {
    pattern: {
      value:
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$/,
      message:
        '올바른 URL 형식이 아닙니다. http:// 또는 https:// 로 시작하는 링크를 입력하세요.',
    },
    required: '링크를 입력해주세요.',
  },
} as const;
