import Link from 'next/link';

import * as Styled from '../index.styles';

const sites = [
  {
    isInternal: false,
    href: 'https://blog.pairy.co',
    text: '세일즈클루 블로그',
  },
  {
    isInternal: false,
    href: 'https://salesclue.gitbook.io/guide/',
    text: '사용 가이드',
  },
  {
    isInternal: false,
    href: 'https://www.pairy.co/',
    text: '채용 정보',
  },
  {
    isInternal: false,
    href: 'https://www.pairy.co/privacy',
    text: '개인정보 처리 방침',
  },
  {
    isInternal: false,
    href: 'https://www.pairy.co/operation_policy',
    text: '이용 약관',
  },
] as const;

const Sites = () => {
  return (
    <ul>
      {sites.map(({ href, text, isInternal }, index) => {
        if (isInternal) {
          return (
            <li key={text}>
              <Link href={href}>
                <Styled.LinkText
                  variant="sm-medium"
                  color="zinc_200"
                  marginTop={index === 0 ? 0 : 16}
                >
                  {text}
                </Styled.LinkText>
              </Link>
            </li>
          );
        }

        return (
          <li key={text}>
            <a href={href} target="_blank" rel="noreferrer noopener nofollow">
              <Styled.LinkText
                variant="sm-medium"
                color="zinc_200"
                marginTop={index === 0 ? 0 : 16}
              >
                {text}
              </Styled.LinkText>
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Sites;
