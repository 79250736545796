import { type PropsWithChildren } from 'react';
import Image from 'next/image';

import Footer from './Footer';
import * as Styled from './index.styles';

interface Props {
  fullWidth?: boolean;
  className?: string;
  isSetPaddingTop?: boolean;
}

const MainLayout = ({
  children,
  fullWidth = false,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <>
      <Styled.Main className={className} fullWidth={fullWidth}>
        <Styled.ImageWrapper
          justifyContent="center"
          marginTop={100}
          onClick={() => {
            window.open('https://salesclue.io', '_blank');
          }}
        >
          <Image
            src="/images/salesclue_logo_white_alpha.png"
            width={174}
            height={40}
          />
        </Styled.ImageWrapper>
        {children}
      </Styled.Main>
      <Footer />
    </>
  );
};

export default MainLayout;
