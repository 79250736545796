import { Flex, Stack, FlexItem } from '@pairy/pds-react';

import Pairy from './Pairy';
import SpringCamp from './SprintCamp';
import Sites from './Sites';
import LinkedIn from './LinkedIn';

const Footer = () => {
  return (
    <Stack
      gap={32}
      justifyContent="center"
      backgroundColor="zinc_900"
      padding={{ x: 16, y: 96 }}
      direction="column"
    >
      <Stack gap={16} direction="column">
        <Pairy />
        <SpringCamp />
      </Stack>
      <Flex>
        <FlexItem flexGrow={1}>
          <Sites />
        </FlexItem>
        <FlexItem flexGrow={1}>
          <LinkedIn />
        </FlexItem>
      </Flex>
    </Stack>
  );
};

export default Footer;
