import { Text } from '@pairy/pds-react';
import styled from '@emotion/styled';

export const Wrapper = styled.footer`
  padding: 96px 180px;
  background-color: ${({ theme }) => theme.colors.zinc_900};
`;

export const InnerWrapper = styled.div`
  width: 1080px;
`;

export const LinkText = styled(Text)`
  cursor: pointer;
`;
