import Image from 'next/image';
import { Flex, Stack, Text } from '@pairy/pds-react';

const SpringCamp = () => {
  return (
    <Stack gap={16} direction="column">
      <Stack gap={8}>
        <Image
          src="/images/spring_camp_logo.png"
          width={26}
          height={43}
          quality={100}
        />
        <Image
          src="/images/strong_ventures_logo.png"
          width={136}
          height={43}
          quality={100}
        />
      </Stack>
      <Flex direction="column">
        <Text as="p" variant="sm-medium" color="zinc_200">
          Backed By
        </Text>
        <Text as="p" variant="lg-medium" color="white">
          Spring Camp, Strong Ventures
        </Text>
      </Flex>
    </Stack>
  );
};

export default SpringCamp;
