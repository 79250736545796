import { Flex, Stack, FlexItem } from '@pairy/pds-react';

import * as Styled from './index.styles';
import Pairy from './Pairy';
import SpringCamp from './SprintCamp';
import Sites from './Sites';
import LinkedIn from './LinkedIn';

const Footer = () => {
  return (
    <Flex
      justifyContent="center"
      backgroundColor="zinc_900"
      padding={{ y: 96 }}
    >
      <Styled.InnerWrapper>
        <Stack gap={16}>
          <FlexItem flexGrow={1}>
            <Pairy />
          </FlexItem>
          <FlexItem flexGrow={1}>
            <SpringCamp />
          </FlexItem>
          <FlexItem flexGrow={1}>
            <Sites />
          </FlexItem>
          <FlexItem flexGrow={1}>
            <LinkedIn />
          </FlexItem>
        </Stack>
      </Styled.InnerWrapper>
    </Flex>
  );
};

export default Footer;
