import Image from 'next/image';
import Link from 'next/link';
import { Button, Flex, FlexItem, Stack, Text, Icon } from '@pairy/pds-react';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import TopBanner from '@/components/Layouts/TopBanner';

import * as Styled from './index.styles';

const drawerMenu = [
  { url: '/login', label: '홈', isExternal: false },
  {
    url: 'https://blog.pairy.co/',
    label: '블로그',
    isExternal: true,
  },
  {
    url: 'https://salesclue.gitbook.io/guide/',
    label: '가이드',
    isExternal: true,
  },
  {
    url: 'https://slashpage.com/b2b-serious-content',
    label: '콘텐츠 함께 만들기',
    isExternal: true,
  },
];

const Header = () => {
  const [isDrawerOpened, setDrawerOpened] = useState(false);
  const router = useRouter();

  const { pathname } = router;

  const moveLinkUrl = ({
    url,
    isExternal = false,
  }: {
    url: string;
    isExternal?: boolean;
  }) => {
    if (isExternal && window) {
      window.open(url);
      return;
    }

    router.push(url);
  };

  const handleDrawer = (event: React.MouseEvent) => {
    event.stopPropagation();
    document.body.style.overflow = isDrawerOpened ? 'unset' : 'hidden';

    setDrawerOpened((prev) => !prev);
  };

  return (
    <Styled.FlexWrapper as="header" position="fixed" direction="column">
      <TopBanner />
      <Flex
        justifyContent="center"
        padding={{ y: 12, x: 16 }}
        backgroundColor="white"
        alignItems="center"
      >
        <FlexItem flexBasis="50%">
          <Stack gap={19} alignItems="center">
            <Icon icon="ph:list" size={24} onClick={handleDrawer} />
            <button
              type="button"
              onClick={() => {
                moveLinkUrl({ url: 'https://salesclue.io' });
              }}
            >
              <Image
                src="/images/salesclue_logo_white_alpha.png"
                width={116}
                height={27}
                priority
              />
            </button>
          </Stack>
        </FlexItem>
        <FlexItem flexBasis="50%">
          <Stack gap={8} justifyContent="flex-end">
            <Link href="/login">
              <Button>로그인</Button>
            </Link>
            <Link href="/signup">
              <Button variant="zinc_700_fill">회원가입</Button>
            </Link>
          </Stack>
        </FlexItem>
        {isDrawerOpened && (
          <>
            <Styled.DrawerWrapper position="absolute" onClick={handleDrawer}>
              <Styled.DrawerContent
                position="absolute"
                backgroundColor="white"
                direction="column"
              >
                <Flex
                  justifyContent="space-between"
                  padding={{ y: 8, x: 16 }}
                  marginBottom={16}
                >
                  <Text variant="lg-semibold">메뉴</Text>
                  <Icon icon="ph:x" size={19} onClick={handleDrawer} />
                </Flex>
                <Stack gap={8} direction="column">
                  {drawerMenu.map((menu) => (
                    <>
                      <Styled.MenuWrapper
                        key={menu.url}
                        padding={{ y: 8, x: 16 }}
                        className={pathname === menu.url ? 'is-active' : ''}
                        onClick={() =>
                          moveLinkUrl({
                            isExternal: menu.isExternal,
                            url: menu.url,
                          })
                        }
                      >
                        <Text
                          variant="lg-semibold"
                          color={
                            menu.label === '콘텐츠 함께 만들기'
                              ? 'viridian_400'
                              : 'zinc_500'
                          }
                        >
                          {menu.label}
                        </Text>
                      </Styled.MenuWrapper>
                    </>
                  ))}
                </Stack>
              </Styled.DrawerContent>
            </Styled.DrawerWrapper>
          </>
        )}
      </Flex>
    </Styled.FlexWrapper>
  );
};

export default Header;
