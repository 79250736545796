import { PropsWithChildren, useEffect, useState } from 'react';

import DesktopMain from './Desktop';
import MobileMain from './Mobile';

interface Props {
  tabChangeHandler?: (value: string) => void;
  isMobile?: boolean;
}

const Main = ({
  children,
  tabChangeHandler,
  isMobile = false,
}: PropsWithChildren<Props>) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) {
    return <></>;
  }

  if (isMobile) {
    return (
      <MobileMain fullWidth tabChangeHandler={tabChangeHandler}>
        {children}
      </MobileMain>
    );
  }

  return <DesktopMain fullWidth>{children}</DesktopMain>;
};

export default Main;
